import logo from './assets/sc-logo.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="sigma codex logo" />
        <p>
          Coming Very Soon!
        </p>
        <small className="Copy">@ 2021 Sigma Codex.</small>
      </header>
    </div>
  );
}

export default App;
